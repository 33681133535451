import React, { useState } from 'react'
import logo from '../../assets/icons/logo.svg'
import dropdownArrow from '../../assets/icons/dropdown-arrow.svg'
import menu from '../../assets/icons/menu.svg'
import close from '../../assets/icons/close.svg'
import email from '../../assets/icons/email.svg'
import instagram from '../../assets/icons/instagram.svg'
import whatsapp from '../../assets/icons/whatsapp.svg'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'


function Navbar() {
    const [mobileMenu, setMenu] = useState(false)
    const [mobileServices, setMobileServices] = useState(false)
    const {t} = useTranslation()

    return (
        <header className='fixed top-0 left-0 w-full z-50'>
            <nav className='bg-bg shadow-sm'>
                <div className='mx-auto py-6 px-4 md:px-12'>
                    <div className='flex justify-between items-center w-full'>
                        <Link to={"/"} className='flex'>
                            <img src={logo} className='w-[120px]'/>
                        </Link>
                        <div className='hidden lg:flex justify-between space-x-12 items-center'>
                            <div className='group relative cursor-pointer'>
                                <Link to={"/services"} className='flex items-center space-x-1 text-lg group-hover:text-lightGray'>
                                    <span>{t('navbar.services')}</span>
                                    <img src={dropdownArrow} width={10} />
                                </Link>
                                <div className='invisible absolute top-8 left-0 group-hover:visible z-50'>
                                    <div className='flex flex-col space-y-2 bg-white shadow-md rounded w-36'>
                                        <Link to={"/services#branding"} className='px-4 py-2 hover:bg-gray-100'>{t('navbar.services-drop.branding')}</Link>
                                        <Link to={"/services#digup"} className='px-4 py-2 hover:bg-gray-100'>{t('navbar.services-drop.digap')}</Link>
                                        <Link to={"/services#promote"} className='px-4 py-2 hover:bg-gray-100'>{t('navbar.services-drop.promotion')}</Link>
                                    </div>
                                </div>
                            </div>
                            <Link to={"/cases"} className='flex items-center font-[400] text-lg hover:text-lightGray'>
                                {t('navbar.cases')}
                            </Link>
                            <Link to={"/vacancies"} className='flex items-center text-lg hover:text-lightGray'>
                                {t('navbar.vacancies')}
                            </Link>
                            <Link to={"/contact"} className='flex items-center text-lg hover:text-lightGray'>
                                {t('navbar.contacts')}
                            </Link>
                        </div>
                        <div className='hidden lg:flex items-center justify-between'>
                            <div className='text-lg pr-3 hover:text-lightGray hover:cursor-pointer'>KAZ</div>
                            <div className='w-[1px] h-[20px] bg-gray-300'></div>
                            <div className='text-lg pl-3 hover:text-lightGray hover:cursor-pointer'>EN</div>
                        </div>
                        <div className='hidden lg:flex items-center space-x-5'>
                            <a className='text-lg hover:text-blue-400' href='mailto:hello@aigla.kz'>hello@aigla.kz</a>
                            <Link to={"/dialog"} className='flex items-center justify-center btn-primary px-8 py-2 uppercase'>
                                <span>{t('navbar.order')}</span>
                            </Link>
                        </div>
                        <img onClick={() => setMenu(true)} className='lg:hidden hover:cursor-pointer' src={menu} />
                    </div>
                </div>
            </nav>
            <div className={`fixed top-0 right-0 z-50 h-[100vh] bg-dark transition-all ease-in-out duration-500 ${mobileMenu ? 'w-[100vw]' : 'w-[0px]'} overflow-hidden`}>
                <div className='flex flex-col p-8 w-full h-full'>
                    <div className='flex justify-end hover:cursor-pointer'>
                        <img onClick={() => setMenu(false)} src={close} />
                    </div>
                    <div className='flex flex-col space-y-10 mt-16'>
                        <div className='group relative cursor-pointer'>
                            <div className='flex text-3xl text-bg' onClick={() => setMobileServices(prev => !prev)}>
                                <span className='mx-auto flex'>{t('navbar.services')} <img src={dropdownArrow} width={12} className='ml-2'/></span>
                            </div>
                            <div className={`z-20 mt-4 transition-all ease-in-out duration-200 ${mobileServices ? 'max-h-[200px]' : 'max-h-[0px]'} overflow-hidden`}>
                                <div className='flex flex-col space-y-4'>
                                    <Link onClick={() => setMenu(false)} to={"/services#branding"} className='text-gray-400 text-2xl mx-auto'>{t('navbar.services-drop.branding')}</Link>
                                    <Link onClick={() => setMenu(false)} to={"/services#digup"} className='text-gray-400 text-2xl mx-auto'>{t('navbar.services-drop.digap')}</Link>
                                    <Link onClick={() => setMenu(false)} to={"/services#promote"} className='text-gray-400 text-2xl mx-auto'>{t('navbar.services-drop.promotion')}</Link>
                                </div>
                            </div>
                        </div>
                        <Link onClick={() => setMenu(false)}  to={"/cases"} className='flex items-center font-[400] text-3xl text-bg mx-auto'>
                            {t('navbar.cases')}
                        </Link>
                        <Link onClick={() => setMenu(false)} to={"/vacancies"} className='flex items-center text-3xl text-bg mx-auto'>
                            {t('navbar.vacancies')}
                        </Link>
                        <Link onClick={() => setMenu(false)} to={"/contact"} className='flex items-center text-3xl text-bg mx-auto'>
                            {t('navbar.contacts')}
                        </Link>
                        
                    </div>
                    <div className='flex items-center justify-between mx-auto mt-16'>
                        <div className='text-bg text-2xl pr-3 hover:text-blue-400 hover:cursor-pointer'>KAZ</div>
                        <div className='w-[1px] h-[25px] bg-gray-400'></div>
                        <div className='text-bg text-2xl pl-3 hover:text-blue-400 hover:cursor-pointer'>EN</div>
                    </div>
                    <div className='flex items-center space-x-8 mt-16 mx-auto'>
                        <a href='mailto:hello@aigla.kz'>
                            <img src={email} width={50}/>
                        </a>
                        <a href='https://www.instagram.com/aigla.kz?igsh=MzRlODBiNWFlZA=='>
                            <img src={instagram} width={38}/>
                        </a>
                        <a href='https://wa.me/77052221923' target='_blank'>
                            <img src={whatsapp} width={50}/>
                        </a>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Navbar